<template>
  <div v-if="isDashboard" class="verde-farmer-top with-sticky-footer-buttons">
    <Card class="card-header-pb-0 card-title-mb-0 card-msg-pt-0" :with-footer="false" :title="title"></Card>
    <div class="table align-items-center">
      <div v-if="stockBatch">
        <div v-if="isStockBatchFull" class="d-flex">
          <div
              :class="{'disabled':isStockBatchFinished, 'bg-white':!isStockBatchFinished, 'clickable':!isStockBatchFinished}"
              class="mr-auto rounded-corner stock-batch block-inline d-block" @click.stop="moveToStockBatch">
            <div class="text-center">
              <img class="icon mb-2" src="../../../../assets/images/verde/daily-orders-list.png"
                   alt="Daily Orders List"/>
              <br class="clearfix"/>
              <div class="d-block w-100">
                <p class="d-block">{{ $t('frontoffice.farmers.offers.dashboard.stock-batch') }}</p>
                <p class="d-block">
                  <strong>{{ $t('frontoffice.farmers.offers.dashboard.interval') }}:</strong>
                  &nbsp;
                  <strong :class="{'text-success': !isStockBatchFinished}">{{ interval }}</strong>
                </p>
              </div>
            </div>
          </div>
          <div class="en-gross ml-auto rounded-corner bg-white clickable d-block" @click.stop="moveToEnGross">
            <div class="text-center">
              <img class="icon mb-2" src="../../../../assets/images/verde/en-gross.png" alt="En Gross"/>
              <br class="clearfix"/>
              <div class="d-block w-100">
                <p>{{ $t('frontoffice.farmers.offers.dashboard.en-gross') }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isStockBatchFew" class="d-flex mb-4"> <!-- SBFEW -->
          <div
              :class="{'disabled':isStockBatchFinished, 'bg-white':!isStockBatchFinished, 'clickable':!isStockBatchFinished}"
              class="mr-auto ml-auto rounded-corner stock-batch-few block-inline d-block"
              @click.stop="moveToStockBatch">
            <div class="text-center">
              <div>
                <img class="icon mb-2 muted" src="../../../../assets/images/verde/hand-holding-dollar-solid.svg"
                     alt="Offer Request"/>
              </div>
              <div class="d-block w-100">
                <p class="d-block">{{ $t('frontoffice.farmers.offers.dashboard.stock-batch-few') }}</p>
                <p class="d-block">
                  <strong :class="{'text-success': !isStockBatchFinished}">{{ interval }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="isStockBatchOffers" class="d-flex mb-4">
          <div class="en-gross mx-auto rounded-corner bg-white clickable d-block" @click.stop="moveToEnGross">
            <div class="text-center">
              <img class="icon mb-2" src="../../../../assets/images/verde/en-gross.png" alt="En Gross"/>
              <br class="clearfix"/>
              <div class="d-block w-100">
                <p>{{ $t('frontoffice.farmers.offers.dashboard.en-gross') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-block w-100 text-center mb-4 mt-4">
        <Spinner class="icon-3x" :simple="true"/>
      </div>
    </div>
    <Card v-if="isStockBatchFull" class="mt-5 card-header-pb-0 card-title-mb-0 card-msg-pt-0" :with-footer="false">
      <div class="table">
        <div class="row">
          <div>{{ $t('frontoffice.farmers.offers.dashboard.stock-batch-summary') }} {{ interval }}:
            {{ stockBatch && stockBatch.getAvailableItemCollection().getSize() || 0 }}
          </div>
        </div>
        <div class="row mt-2">
          <div class="d-block">
            <strong>{{ $t('frontoffice.farmers.offers.dashboard.en-gross-summary') }}</strong>:
            <br class="clearfix"/>
            <span v-if="!hasEnGrossOffers">{{ $t('frontoffice.farmers.offers.dashboard.en-gross-none') }}</span>
            <div v-else class="font-weight-semibold">
              <span class="d-block" v-for="(product) in enGrossProducts" :key="product.getId()">{{ product.getName() }}, {{ formatQty(product.getQty(), product.getUnit()) }}, {{ formatPrice(product.getPrice(), product.getCurrency()) }}</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
    <div class="mt-2" v-if="stockBatch">
      <WhatsappButton :label="$t('frontoffice.farmers.share.button_label')"
                      :share-text="$t('frontoffice.farmers.share.share_text')"/>
    </div>
  </div>
  <router-view v-if="stockBatch" :stock-batch="stockBatch" :offers="enGrossProducts" @update="update"></router-view>
</template>

<script>
import StockBatch from "@/entities/StockBatch";
import {DatesMixin, NumbersMixin} from "@/mixins/GeneralMixin";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import Card from "@/components/elements/Card";
import EnGrossProduct from "@/entities/EnGrossProduct";
import WhatsappButton from "@/components/elements/WhatsappButton.vue";
import Spinner from "@/components/elements/Spinner.vue";

export default {
  name: "OfferDashboard",
  mixins: [DatesMixin, LoadingModalMixin, NumbersMixin],
  components: {Spinner, Card, WhatsappButton},
  data: function () {
    return {
      stockBatch: null,
      farmer: null,
      enGrossProducts: [],
      wasJustProcessed: false,
    }
  },
  beforeMount: function () {
    this.init();
  },
  computed: {
    isStockBatchFinished: function () {
      return this.stockBatch && this.stockBatch.isFinished();
    },
    isStockBatchFull: function () {
      return this.stockBatch && this.stockBatch.isTypeFull();
    },
    isStockBatchFew: function () {
      return this.stockBatch && this.stockBatch.isTypeFew();
    },
    isStockBatchOffers: function () {
      return this.stockBatch && this.stockBatch.isTypeOffers();
    },
    isDashboard: function () {
      return this.$route.name === 'frontoffice.breadcrumb.farmers.offers.dashboard';
    },
    interval: function () {
      return (this.stockBatch && this.formatDate(this.stockBatch.getStartAt()) + " - " + this.formatDate(this.stockBatch.getEndAt())) || '-';
    },
    title: function () {
      return this.$t('frontoffice.farmers.offers.dashboard.title', {farmer: (this.farmer && this.farmer.getName()) || '-'});
    },
    hasEnGrossOffers: function () {
      return this.enGrossProducts.length > 0;
    },
  },
  methods: {
    init: function () {
      this.stockBatch = null;
      this.farmer = null;
      this.wasJustProcessed = false;
      this.getStockBatch();
    },
    moveToStockBatch() {
      if (!this.isStockBatchFinished) {
        this.$router.push({
          name: 'frontoffice.breadcrumb.farmers.offers.stock-batch',
          params: {id: this.farmer.getId(), batchId: this.stockBatch.getId()}
        });
      }
    },
    moveToEnGross() {
      this.$router.push({
        name: 'frontoffice.breadcrumb.farmers.offers.en-gross',
        params: {id: this.farmer.getId(), batchId: this.stockBatch.getId()}
      });
    },
    getStockBatch: function () {
      let that = this;
      this.queueJob();
      this.axios.get(this.$store.state.config.getStockBatchUri(this.$route.params.batchId)).then(
          response => {
            let content = response.data;
            that.stockBatch = new StockBatch(content.data);
            that.farmer = that.stockBatch.getFarm();
            that.getEnGrossProducts();
            if (that.stockBatch.isTypeOffers()) {
              //that.moveToEnGross();
            }
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.$store.commit('breadcrumb', that.farmer && that.farmer.getName());
        that.completeJob();
      });
    },
    update: function (isStockBatchUpdate = false, isOffersUpdate = false) {
      if (isStockBatchUpdate) {
        this.getStockBatch();
      }

      if (isOffersUpdate) {
        this.getEnGrossProducts();
      }
    },
    getEnGrossProducts: function () {
      let that = this;
      if (!this.stockBatch.isTypeFull() && !this.stockBatch.isTypeOffers()) {
        return;
      }
      this.queueJob();
      this.axios.get(this.$store.state.config.getEnGrossProductsUri(this.farmer.getId())).then(
          response => {
            let content = response.data;
            that.enGrossProducts = [];
            content.data.data.forEach(function (product) {
              that.enGrossProducts.push(new EnGrossProduct(product));
            });
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>
.stock-batch .icon,
.en-gross .icon {
  width: 45%;
  max-width: 200px;
}

.stock-batch-few .icon {
  width: 45%;
  max-width: 100px;
}

.stock-batch-few,
.stock-batch,
.en-gross {
  width: 45%;
  max-width: 45%;
}

.rounded-corner {
  border-radius: 25px;
  border: none;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .05)
}

.col-2 {
  -ms-flex: 0 0 10.67%;
  flex: 0 0 9.67%;
  max-width: 10.67%
}

img.muted {
  filter: invert(62%) sepia(9%) saturate(14%) hue-rotate(314deg) brightness(98%) contrast(84%);
}

@media (min-width: 768px) {
  .col-md-2 {
    -ms-flex: 0 0 13.67%;
    flex: 0 0 13.67%;
    max-width: 13.67%
  }
}
</style>
